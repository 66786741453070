<template>
  <div class="expande-horizontal column">
    <span class="fonte grey--text fonteMini mx-2">Selecione o plano para copiar:</span>
    <v-flex xs12 class="px-0">
      <v-card color="#f2f2f2" outlined class="expande-horizontal centraliza plan-options elevations-3">
        <v-btn 
          style="height: 40px;"
          x-small
          :color="$theme.primary"
          :text="!plan_selected || (plan_selected && plan_selected._id != plan._id)" 
          :dark="(plan_selected && plan_selected._id === plan._id)" 
          v-for="plan in plans" :key="plan._id" class="ma-1" @click="selectPlan(plan)">{{ plan.name }}</v-btn>
      </v-card>
    </v-flex>
    <v-list
      class="pa-0 ma-0 fonte"
      :dark="$theme.dark"
      :light="$theme.light"
      two-line
      dense
      background-color="transparent"
      style="min-height: 20vh; max-height: 90vh; overflow: auto;"
    >
      <template class="px-3">
        <v-list-item v-if="plan_selected" style="background: #f2f2f2;" class="fonte mb-3">
          <v-list-item-content>
            <v-list-item-title>
              Seu link de venda
            </v-list-item-title>
            <span class="link-seller fonteMini">
              https://app.stonepro.com.br/#/comprar-plano/{{ plan_selected ? plan_selected.identifier : "" }}?s_id={{ getLoggedUser.id_seller }}
            </span>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn small color="deep-purple" dark @click="copyLink">copiar</v-btn>
          </v-list-item-action>
        </v-list-item>
      </template>
      <template v-for="(sale, i) in showFiltereds">
        <v-list-item
          @click="open(sale)"
          style="border-radius: 6px;"
          :key="sale._id"
          class="fonte pl-2 pr-1 animate__animated animate__fadeInUp"
        >
          <v-avatar color="#f2f2f2" class="mr-2">
            <v-icon :color="$theme.primary">mdi-storefront-plus</v-icon>
          </v-avatar>
          <v-list-item-content class="">
            <v-list-item-title style="font-size: 12pt;">
              {{ $helper.formataSaldo(sale.plan_id.value_cents / 2) }} - {{ sale.plan_id.name }}
            </v-list-item-title>
            <v-list-item-subtitle class="fonte">
              <v-icon size="14" :color="$theme.primary">mdi-account-circle-outline</v-icon> {{ sale.user_id.nome }}
            </v-list-item-subtitle>
            <v-list-item-subtitle>
              <v-icon size="14" :color="$theme.primary">mdi-calendar-clock</v-icon>
              <span class="fonte fonteMini">
                {{ $moment(sale.created_at).format('DD/MM/YY [às] HH:mm') }}
              </span>
            </v-list-item-subtitle>
            <v-list-item-subtitle>
              <span class="fonte" :class="sale.commission_ready ? 'green--text' : 'orange--text'">
                <v-icon size="10" :color="sale.commission_ready ? 'green' : 'orange'">mdi-circle</v-icon>
                {{ sale.comission_ready ? 'Comissão paga' : 'Comissão aguardando pagamento do cliente' }}
              </span>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider v-if="i < showFiltereds.length - 1" :key="i"></v-divider>
      </template>
      <v-list-item
        style="min-height: 200px;"
        v-intersect="onIntersect"
      ></v-list-item>
    </v-list>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  props: {
    filtereds: {
      type: Array,
      default: () => [],
    }
  },
  data() {
    return {
      itemsToShow: 12,
      plans: [],
      plan_selected: false,
    };
  },
  computed: {
    ...mapGetters(["get_sale", "getLoggedUser"]),
    showFiltereds() {
      return this.filtereds.slice(0, this.itemsToShow);
    }
  },
  methods: {
    ...mapActions(['createGlobalMessage', "disableLoading", "enableLoading"]),
    onIntersect() {
      this.itemsToShow += 12;
    },
    listPlans() {
      this.enableLoading()
      this.$run('checkout/list-plans', {}, "client")
      .then(res => {
          this.disableLoading()
          this.plans = res.data
        })
    },
    open(client) {
      this.$store.commit("set_sale", client);
    },
    selectPlan(plan) {
      this.plan_selected = plan
    },
    copyLink() {
      const el = document.createElement("textarea");
      el.value = `https://app.stonepro.com.br/#/comprar-plano/${this.plan_selected.identifier}?s_id=${this.getLoggedUser.id_seller}`;
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
      this.createGlobalMessage({
        message: "O link copiado para sua área de transferência!",
        type: "success",
        timeout: 5000
      });
    },
  },
  mounted() {
    this.listPlans();
  }
};
</script>

<style>
.plan-options {
  border-radius: 0px;
  padding: 1px;
  background: #aeadae;
}
</style>
